import { Box, Heading, Text } from 'grommet';

import Comments from '../Comments/Comments';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { config } from '../../configs/config';

export default function Article({ post }) {
  const shouldHideComments = post.hideComments === 'true';
  return (
    <Box
      align="start"
      flex
      style={{ maxWidth: '700px', paddingLeft: '10px', paddingRight: '10px' }}
    >
      <Head>
        <title>{post.title}</title>
        <link rel="shortcut icon" href="/544332.png" />
        <link href={config.links.themes.codeHighlighter} rel="stylesheet" />
      </Head>
      {post.showMetadata === 'true' && (
        <Box>
          <Heading level={1} size="40px">
            {post.title}
          </Heading>
          <Box direction="row">
            {post.tag.split(',').map((tag) => {
              return (
                <Text
                  key={Math.random()}
                  size="14px"
                  margin={{
                    top: '10px',
                    bottom: '10px',
                    right: '5px'
                  }}
                  border="solid"
                  style={{
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#282C35',
                    borderRadius: 12,
                    paddingLeft: 10,
                    paddingRight: 10,
                    background: '#282C35',
                    color: 'white'
                  }}
                >
                  {tag}
                </Text>
              );
            })}
          </Box>
        </Box>
      )}
      <div
        dangerouslySetInnerHTML={{ __html: post.postContent }}
        style={{ lineHeight: '2', fontSize: '16' }}
      />
      {/* <Markdown>{post.postContent}</Markdown> */}
      {!shouldHideComments && <Comments />}
    </Box>
  );
}

Article.propTypes = {
  post: PropTypes.object
};
