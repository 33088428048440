import { Box, Grid } from 'grommet';

import Article from '../Article/Article';
import ArticleList from '../ArticleList/ArticleList';
import { CONTENT } from '../../constants/content';
import Footer from '../Footer/_Footer';
import Head from 'next/head';
import Main from '../Main/_ Main';
import Navigator from '../Navigator/Navigator';
import PropTypes from 'prop-types';

export default function Layout({
  post,
  showArticleList = false,
  allPostsData,
  renderNonPostContent = false,
  children
}) {
  return (
    <Grid
      rows={['xxsmall', 'flex', 'xsmall']}
      columns={['', '', '']}
      areas={[['header'], ['main'], ['footer']]}
      gap="small"
      alignContent={'center'}
      style={{ minHeight: '100vh' }}
    >
      <Head>
        <title>{CONTENT.TEXT.SITE_TITLE}</title>
        <link rel="site icon" href="/544332.png" />
      </Head>
      <Box gridArea="header">
        <Navigator />
      </Box>

      <Box gridArea="main">
        {!renderNonPostContent &&
          (post && !showArticleList ? (
            <Main>
              <Article post={post}></Article>
            </Main>
          ) : (
            <Box align="center">
              <ArticleList allPostsData={allPostsData} />
            </Box>
          ))}
        {children}
      </Box>

      <Box gridArea="footer">
        <Footer />
      </Box>
    </Grid>
  );
}

Layout.propTypes = {
  post: PropTypes.object,
  showArticleList: PropTypes.bool,
  renderNonPostContent: PropTypes.bool,
  allPostsData: PropTypes.array
};
