const giscusConfig = Object.freeze({
  src: 'https://giscus.app/client.js',
  'data-repo': 'Nitinkumar-Gove/blog-comments',
  'data-repo-id': 'R_kgDOGO7r8w',
  'data-mapping': 'pathname',
  'data-reactions-enabled': '1',
  'data-theme': 'light',
  'data-input-position': 'bottom',
  'data-lang': 'en',
  crossorigin: 'anonymous',
  'data-category': 'General',
  'data-category-id': 'DIC_kwDOGO7r884CONTW',
  async: true
});

export default giscusConfig;
