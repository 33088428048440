import React, { Component } from 'react';

import COMMENT_ENGINES from '../../constants/commentEngine';
import { buildCommentsEngine } from '../../utils/commentsBuilder';

export default class Comments extends Component {
  constructor(props) {
    super(props);
    this.commentBox = React.createRef(); // use ref to create our element
  }

  componentDidMount() {
    this.commentBox.current.appendChild(
      buildCommentsEngine(COMMENT_ENGINES.GISCUS)
    );
  }

  render() {
    return (
      <div style={{ width: '100%' }} id="comments">
        <div ref={this.commentBox} />
      </div>
    );
  }
}
