import { Box, Main } from 'grommet';

import PropTypes from 'prop-types';

export default function _Main({ children }) {
  return (
    <Box align="center">
      <Main justify={'center'}>{children}</Main>
    </Box>
  );
}

_Main.propTypes = {
  children: PropTypes.object
};
