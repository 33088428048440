const ABOUT_CONTENT = `
  ### Hello 👋, 

  Welcome to my blog !

  This is the place where I write about my **experiements 🧪 and learnings** 📘 from the world of software engineering. I have been working as a software engineer 
  since year 2012. Currently I am working with Intuit **building fintech products** for small business owners and individuals. 

  #### Favorite Quote
  Work Hard. Stay Humble. Be Kind.
  
  #### Call to action
  I’m always looking for a new exciting problem to solve. Feel free to reach me out at gove.nitinkumar@gmail.com.
`;

export const CONTENT = {
  BUTTONS: {
    TAKE_ME_BACK: 'Gotcha ! Take me back !'
  },
  TEXT: {
    OOPS: 'Oops, Its a miss !',
    SITE_TITLE: 'Gove MentalLab',
    ABOUT_CONTENT: ABOUT_CONTENT
  }
};
