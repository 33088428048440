export const config = {
  links: {
    images: {
      home: '/',
      pageNotFound:
        'https://media3.giphy.com/media/aYpmlCXgX9dc09dbpl/giphy.gif?cid=ecf05e47876l28uq4tjd2djs0piqxhpk7n6ppkyrj4tbyq0s&rid=giphy.gif&ct=g'
    },
    themes: {
      codeHighlighter:
        'https://cdnjs.cloudflare.com/ajax/libs/prism-themes/1.9.0/prism-vsc-dark-plus.min.css' // themes -> https://cdnjs.com/libraries/prism-themes
    }
  },
  fonts: {
    family: 'Jost'
  }
};
