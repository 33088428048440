import { Anchor, Header, Menu, Nav, ResponsiveContext } from 'grommet';

import { Menu as _Menu } from 'grommet-icons';

export default function Navigator() {
  return (
    <Header pad="medium" align="center" background="brand">
      <Anchor
        size={'xlarge'}
        label={'Nitinkumar Gove'}
        margin={{ start: 'small' }}
        href={'/'}
        color="light-2"
        style={{ fontSize: '22px' }}
      ></Anchor>
      <ResponsiveContext.Consumer>
        {(responsive) =>
          responsive === 'small' ? (
            <Menu
              icon={<_Menu style={{ lineHeight: '2' }} />}
              items={[
                {
                  label: 'Articles',
                  onClick: () => {
                    window.location.href = '/';
                  }
                },
                {
                  label: 'Bookshelf',
                  onClick: () => {
                    window.location.href = '/posts/bookshelf';
                  }
                },
                {
                  label: 'JHMC',
                  onClick: () => {
                    window.location.href = '/posts/masterclass';
                  }
                },
                {
                  label: 'Podcasts',
                  onClick: () => {
                    window.location.href = '/posts/podcasts';
                  }
                },
                {
                  label: 'SAMC',
                  onClick: () => {
                    window.location.href = '/posts/studyabroad';
                  }
                }

                // {
                //   label: 'About',
                //   onClick: () => {
                //     window.location.href = '/posts/about';
                //   }
                // }
              ]}
            />
          ) : (
            <Nav direction="row" margin={{ end: 'large' }}>
              <Anchor
                href={'/'}
                label="Articles"
                color="light-2"
                style={{ fontSize: '20px' }}
              />
              <Anchor
                href={'/posts/bookshelf'}
                label="Bookshelf"
                color="light-2"
                style={{ fontSize: '20px' }}
              />
              <Anchor
                href={'/posts/masterclass'}
                label="JHMC"
                color="light-2"
                style={{ fontSize: '20px' }}
              />
              <Anchor
                href={'/posts/podcasts'}
                label="Podcasts"
                color="light-2"
                style={{ fontSize: '20px' }}
              />
              <Anchor
                href={'/posts/studyabroad'}
                label="SAMC"
                color="light-2"
                style={{ fontSize: '20px' }}
              />

              {/* <Anchor
                href={'/posts/about'}
                label="About"
                color="light-2"
                style={{ fontSize: '20px' }}
              /> */}
            </Nav>
          )
        }
      </ResponsiveContext.Consumer>
    </Header>
  );
}
