import { config } from '../configs/config';

export const siteTheme = Object.freeze({
  global: {
    font: {
      family: config.fonts.family
    },
    colors: {
      // icon: {
      //   0: '#',
      //   1: '6',
      //   2: '6',
      //   3: '6',
      //   4: '6',
      //   5: '6',
      //   6: '6',
      //   dark: '#f8f8f8',
      //   light: '#666666'
      // },
      // active: 'rgba(221,221,221,0.5)',
      // black: '#000000',
      // border: {
      //   dark: 'rgba(255,255,255,0.33)',
      //   light: 'rgba(0,0,0,0.33)'
      // },
      brand: '#282C35',
      // control: {
      //   dark: 'accent-1',
      //   light: 'brand'
      // },
      // focus: '#2AD2C9',
      // placeholder: '#AAAAAA',
      // selected: 'brand',
      // text: {
      //   dark: '#f8f8f8',
      //   light: '#444444'
      // },
      // white: '#FFFFFF',
      background: '#F8F8F8'
    }
  }
});
