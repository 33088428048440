import COMMENT_ENGINES from '../constants/commentEngine';
import giscusConfig from '../configs/giscusConfig';
import utterancesConfig from '../configs/utterancesConfig';

const getCommentEngineConfig = (commentEngine) => {
  switch (commentEngine) {
    case COMMENT_ENGINES.GISCUS:
      return giscusConfig;
    case COMMENT_ENGINES.UTTERANCES:
      return utterancesConfig;
    default:
      return {};
  }
};

export const buildCommentsEngine = (commentEngine) => {
  let scriptElement = document.createElement('script');
  const commentEngineConfig = getCommentEngineConfig(commentEngine);
  for (const [key, value] of Object.entries(commentEngineConfig)) {
    scriptElement.setAttribute(key, value);
  }
  return scriptElement;
};
