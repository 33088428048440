import { Anchor, Box, Footer, Text } from 'grommet';
import {
  Book,
  Clipboard,
  Github,
  Linkedin,
  Test,
  Youtube
} from 'grommet-icons';

const Social = () => (
  <Box direction="row" gap="xxsmall" justify="center">
    <Anchor
      a11yTitle="Checkout my work on github"
      href="https://github.com/Nitinkumar-Gove"
      icon={<Github />}
    />
    <Anchor
      a11yTitle="Lets connect on linkedIn"
      href="https://www.linkedin.com/in/nitinkumargove/"
      icon={<Linkedin />}
    />
    <Anchor
      a11yTitle="Checkout my Google Scholar profile"
      href="https://scholar.google.com/citations?user=yRC4FDgAAAAJ&hl=en"
      icon={<Test />}
    />
    <Anchor
      a11yTitle="Checkout my Bookshelf"
      href="https://www.nitinkumargove.com/posts/bookshelf"
      icon={<Book />}
    />
    <Anchor
      a11yTitle="Checkout YT channel"
      href="https://www.youtube.com/@nitinkumargove/"
      icon={<Youtube />}
    />
    <Anchor
      a11yTitle="Checkout My Resume"
      href="https://drive.google.com/file/d/1pxmry4m-pG7vy73pCHyhJpH05rb-c1BF/view?usp=share_link"
      icon={<Clipboard />}
    />
  </Box>
);

export default function _Footer() {
  return (
    <Footer
      justify={'center'}
      pad={{ horizontal: 'large', vertical: 'small' }}
      background="brand"
    >
      <Box direction="column" gap="xxsmall">
        <Social />
        <Text textAlign="center" size="small" weight={'bold'}>
          {`Copyright ${new Date().getFullYear()} | Nitinkumar Gove`}
        </Text>
      </Box>
    </Footer>
  );
}
