const utterancesConfig = Object.freeze({
  theme: 'github-light',
  src: 'https://utteranc.es/client.js',
  crossorigin: 'anonymous',
  async: true,
  repo: 'Nitinkumar-Gove/blog-comments',
  'issue-term': 'pathname'
});

export default utterancesConfig;
