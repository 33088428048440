import { Anchor, Box, Heading, Text } from 'grommet';

import PropTypes from 'prop-types';

export default function ArticleList({ allPostsData = [] }) {
  allPostsData = allPostsData.filter(
    ({ params }) => !!params.title && params.shouldPublish === 'true'
  );
  return (
    <Box
      align="start"
      flex
      style={{ maxWidth: '700px', paddingLeft: '10px', paddingRight: '10px' }}
    >
      <Heading level={1}>All Articles</Heading>
      {allPostsData.map(({ params }, index) => {
        return (
          <Box style={{ paddingBottom: '15px' }} key={params.id}>
            <Anchor
              key={params.id}
              href={`/posts/${params.id}`}
              label={`${allPostsData.length - index}. ${params.title}`}
              size={'xlarge'}
              style={{ lineHeight: '1.7' }}
            />
            <Box direction="row">
              {params.tag.split(',').map((tag) => {
                return (
                  <Text
                    key={Math.random()}
                    size="14px"
                    margin={{
                      top: '10px',
                      bottom: '10px',
                      right: '5px'
                    }}
                    border="solid"
                    style={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#282C35',
                      borderRadius: 12,
                      paddingLeft: 10,
                      paddingRight: 10,
                      background: '#282C35',
                      color: 'white'
                    }}
                  >
                    {tag}
                  </Text>
                );
              })}
            </Box>
            <Text size="medium" style={{ lineHeight: '2' }}>
              {params.description}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
}

ArticleList.propTypes = {
  allPostsData: PropTypes.array
};
